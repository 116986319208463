.App {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

input {
  padding: 10px;
  margin: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
}

ul {
  text-align: left;
  margin-left: 40%;
}
